import React, { useEffect } from 'react'
import styles from './home.module.scss'
import { useDeviceStore } from '../../stateManager/DeviceStore'
import { DEVICE, PLATFORM, THEME } from '../../_shared/constants/constants'
import Camera_header from '../../assets/webp/Camera_header.webp'
import Camera_header_Mobile from '../../assets/webp/camera_header_mobile.webp'
import Iphone_desktop from '../../assets/webp/iphone_camera_desktop.webp'
import Iphone_Mobile from '../../assets/webp/iphone_camera_mobile.webp'
import QR_code from '../../assets/webp/Camera_QR_Code.webp'
import Camera_Illustration1_1 from '../../assets/webp/camera_illustration1.1.webp'
import Camera_Illustration1_2 from '../../assets/webp/camera_illustration1.2.webp'
import Camera_Illustration1_3 from '../../assets/webp/camera_illustration1.3.webp'
import Camera_Illustration2 from '../../assets/webp/camera_illustration2.webp'
import Camera_Illustration2_mobile from '../../assets/webp/camera_illustration2_mobile.webp'
import Camera_Illustration3 from '../../assets/webp/camera_illustration3.webp'
import SetsApart_desktop from '../../assets/webp/setsApart_desktop.webp'
import SetsApart_mobile from '../../assets/webp/setsApart_mobile.webp'
import Iphone_footer from '../../assets/webp/iphone_footer_desktop.webp'
import InvisiblePhotos from '../../assets/webp/invisible_photos_mobile.webp'
import { ReactComponent as AppStoreLogo } from '../../assets/svg/appStore_logo.svg'
import { ReactComponent as PlayStoreLogo } from '../../assets/svg/playStore_logo.svg'

import { ReactComponent as CameraFeature1 } from '../../assets/svg/camera_feature1.svg'
import { ReactComponent as CameraFeature2 } from '../../assets/svg/camera_feature2.svg'
import { ReactComponent as CameraFeature3 } from '../../assets/svg/camera_feature3.svg'
import { ReactComponent as CameraFeature4 } from '../../assets/svg/camera_feature4.svg'
import { ReactComponent as CameraFeature5 } from '../../assets/svg/camera_feature5.svg'
import { ReactComponent as CameraFeature6 } from '../../assets/svg/camera_feature6.svg'
import { Link } from 'react-router-dom'
import { useDownloadHook } from '../../_shared/hooks/download.hook'
import { useOsStore } from '../../stateManager/OsStore'

import { lazily } from 'react-lazily';
const { FloaterDownload } = lazily(() => import('../../_shared/components/FloaterDownload/floaterDownload.component'));

function Home() {
    const { device, setDevice } = useDeviceStore();
    const {
        handleBuildDownload,
    } = useDownloadHook();
    const { os } = useOsStore();


    return (
        <div className={styles.homeContainer}>
            <FloaterDownload title="To protect your media" buttonText="Download" mode={`${THEME.LIGHT}`} />
            <div className={styles.headerContainer}>
                <div className={styles.header}>
                    <div className={styles.heading}>
                        Keep your private life <span>private.</span>
                    </div>
                    <div className={styles.subHeading}>
                        Secure Shots, Sealed Privacy. Capture Memories Carefree. They are never revealed to anyone else.
                    </div>

                </div>
                <div className={styles.headerImg}>
                    <img src={device == DEVICE.MOBILE ? `${Camera_header_Mobile}` : `${Camera_header}`} alt='Zunu camera Header' />
                </div>
            </div>

            <div className={styles.mailContentContainer}>
                <div className={styles.downloadContainer}>
                    <div className={styles.downloadChildContainer}>
                        <div className={styles.downloadLeftContainer}>
                            <p className={styles.downloadHeader}>Protect Your Memories with Zunu Camera's Unmatched Privacy.</p>

                            <div className={styles.download_Os_container}>
                                {device !== DEVICE.MOBILE &&
                                    <div className={styles.downloadBtnContainer}>
                                        <button className={styles.downloadBtn} onClick={() => handleBuildDownload(os)}>Download Now</button>


                                        <div className={styles.osLogoContainer}>
                                            <Link to="" onClick={() => handleBuildDownload(PLATFORM.IOS)}><AppStoreLogo className={styles.osLogos} /></Link>
                                            <Link to="" onClick={() => handleBuildDownload(PLATFORM.AND)}><PlayStoreLogo className={styles.osLogos} /></Link>
                                        </div>

                                    </div>
                                }

                            </div>

                        </div>
                        <div className={styles.downloadImgContainer}>
                            <img src={device == DEVICE.MOBILE ? `${Iphone_Mobile}` : `${Iphone_desktop}`} />
                        </div>


                        {device == DEVICE.MOBILE &&
                            <div className={styles.qrAndLogoContainer}>
                                {/* <div className={styles.qrCodeScanner}>
                                    <img src={QR_code} alt='QR scanner' />
                                </div> */}
                                <div className={styles.osLogoContainer}>
                                    <Link to="" onClick={() => handleBuildDownload(PLATFORM.IOS)}><AppStoreLogo className={styles.osLogos} /></Link>
                                    <Link to="" onClick={() => handleBuildDownload(PLATFORM.AND)}><PlayStoreLogo className={styles.osLogos} /></Link>
                                </div>
                            </div>
                        }
                    </div>
                </div>

            </div>
            <div className={`${styles.mailFeatures_Container}  ${styles.mailContentContainer}`}>
                <div className={styles.mailFeatures_header}>
                    Capture Moments, Keep Privacy with Zunu Camera.
                </div>
                <div className={styles.mail_Features_content}>
                    <div className={styles.featureContainer}>
                        <div className={styles.mail_feature}>
                            <CameraFeature1 className={styles.featureIcons} />
                            <div className={styles.descriptionTextContainer}>
                                <p className={styles.featureDescription}>
                                    Capture with Confidence:
                                </p>
                                <p className={styles.featureDescriptionText}>Capture with Confidence. Media Stayss Completely Private all the time.</p>
                            </div>
                        </div>
                        <div className={styles.mail_feature}>
                            <CameraFeature2 className={styles.featureIcons} />
                            <div className={styles.descriptionTextContainer}>
                                <p className={styles.featureDescription}>
                                    Hidden from Apps:
                                </p>
                                <p className={styles.featureDescriptionText}>Media Captured is Invisible to 3rd Party apps with Gallery Access permissions.</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.featureContainer}>
                        <div className={styles.mail_feature}>
                            <CameraFeature3 className={styles.featureIcons} />
                            <div className={styles.descriptionTextContainer}>
                                <p className={styles.featureDescription}>
                                    Tamper-Proof:
                                </p>
                                <p className={styles.featureDescriptionText}>Photos and Videos Cannot Be Altered or Morphed.</p>
                            </div>
                        </div>
                        <div className={styles.mail_feature}>
                            <CameraFeature4 className={styles.featureIcons} />
                            <div className={styles.descriptionTextContainer}>
                                <p className={styles.featureDescription}>
                                    Dark Web Safe:
                                </p>
                                <p className={styles.featureDescriptionText}>Media is Protected from Harmful AI and Dark Web.</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.featureContainer}>
                        <div className={styles.mail_feature}>
                            <CameraFeature5 className={styles.featureIcons} />
                            <div className={styles.descriptionTextContainer}>
                                <p className={styles.featureDescription}>
                                    Secure from Attacks:
                                </p>
                                <p className={styles.featureDescriptionText}>Media is Safe from Ransomware, Malware, and Viruses</p>
                            </div>
                        </div>
                        <div className={styles.mail_feature}>
                            <CameraFeature6 className={styles.featureIcons} />
                            <div className={styles.descriptionTextContainer}>
                                <p className={styles.featureDescription}>
                                    Breach Protection:
                                </p>
                                <p className={styles.featureDescriptionText}>Media Remains Secure Even if Phone Is Compromised.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${styles.simplifiedPrivacyContainer} ${styles.mailContentContainer}`}>
                <div className={styles.simplifiedPrivacyChild}>
                    <div className={styles.simplifiedHeader}>
                        <div className={styles.mailFeatures_header}>
                            Invisible Photos & Videos
                        </div>
                    </div>
                    {device !== DEVICE.MOBILE ?
                        <div className={styles.privacyContainer}>
                            <div className={styles.privacyContent}>
                                <img src={Camera_Illustration1_1} alt='add Account' />
                                <p className={styles.illustration_Description}>Capture Moments Effortlessly</p>
                            </div>
                            <div className={styles.privacyContent2}>
                                <img src={Camera_Illustration1_2} alt='add Account' />
                                <p className={styles.illustration_Description}>Media Stored in the Zunu Ecosystem</p>
                            </div>

                            <div className={styles.privacyContent3}>
                                <img src={Camera_Illustration1_3} alt='add Account' />
                                <p className={styles.illustration_Description}>Invisible in Phone's Gallery</p>
                            </div>
                        </div>
                        :
                        <div className={styles.privacyContainers}>
                            <img src={InvisiblePhotos} />
                        </div>
                    }
                </div>

            </div>
            <div className={`${styles.mailPrivacyContainer} ${styles.mailContentContainer}`}>
                <div className={styles.mailFeatures_header}>
                    Media is Private on Device.
                </div>
                <div className={styles.illustration4img}>
                    <p className={styles.illustration4Text}>3rd party apps with Gallery access permissions can't see Media- No more data sharing, Targeted Ads or Misuse.</p>
                    <img src={device == DEVICE.MOBILE ? `${Camera_Illustration2_mobile}` : `${Camera_Illustration2}`} alt='illustration' />
                </div>
            </div>

            <div className={`${styles.illustrationContainer} ${styles.mailContentContainer}`}>
                <div className={styles.mailFeatures_header}>
                    Securely Shielded
                </div>
                <div className={styles.illustrationTextImg}>
                    <div className={styles.illustrationText}>
                        <p className={styles.illustrationSubHeader}>Photos <span>and</span> Videos <span>are immune to</span> Ransomware<span>,</span> Malware<span>, and</span>  Viruses.</p>
                    </div>
                    <div className={styles.illustrationImage}>
                        <img src={Camera_Illustration3} alt='mail illustration' />
                    </div>
                </div>
            </div>
            <div className={`${styles.setUsApartContainer} ${styles.mailContentContainer}`}>
                <div className={styles.setApartHeader}>
                    <div className={styles.mailFeatures_header}>
                        What sets us Apart?
                    </div>
                    <div className={styles.mailFeatureSubHeader}>Our ultimate robust Device-to-Device End-to-End Encryption for 100% Privacy</div>

                </div>
                <div className={styles.setApartImg}>
                    <img src={device == DEVICE.MOBILE ? `${SetsApart_mobile}` : `${SetsApart_desktop}`} alt='illustration' />
                </div>
            </div>

            <div className={styles.footerContainer}>
                <div className={styles.footerLeftContainer}>
                    <p className={styles.footerText_header}>Protect Your Memories with Zunu Camera's Unmatched Privacy.</p>
                    <div className={styles.download_Qr_scanner}>
                        {device !== DEVICE.MOBILE &&
                            <div className={styles.footerDownload_container}>

                                <div className={styles.footerBtn_container}>
                                    <button className={styles.downloadBtn}>Download Now</button>
                                </div>

                                <div className={styles.footerLogoContainer}>
                                    <Link to="" onClick={() => handleBuildDownload(PLATFORM.IOS)}><AppStoreLogo className={styles.osLogos} /></Link>
                                    <Link to="" onClick={() => handleBuildDownload(PLATFORM.AND)}><PlayStoreLogo className={styles.osLogos} /></Link>
                                </div>

                            </div>
                        }
                    </div>
                </div>
                <div className={styles.footerImgContainer}>
                    <div className={styles.footerImg}>
                        <img src={device == DEVICE.MOBILE ? `${Iphone_footer}` : `${Iphone_footer}`} alt='pixel mobile' />
                    </div>

                    {device == DEVICE.MOBILE &&
                        <div className={styles.qrAndLogoContainer}>
                            <div className={styles.footerLogoContainer}>
                                <Link to="" onClick={() => handleBuildDownload(PLATFORM.IOS)}><AppStoreLogo className={styles.osLogos} /></Link>
                                <Link to="" onClick={() => handleBuildDownload(PLATFORM.AND)}><PlayStoreLogo className={styles.osLogos} /></Link>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export { Home }